import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="categories--admin--settings--form-fields"
export default class extends Controller {
  static targets = ["searchInput", "resetButton"];

  connect() {
    this.toggleResetButton(); // Initialize the reset button visibility
  }

  resetSearch(event) {
    event.preventDefault();
    this.searchInputTarget.value = "";
    this.searchInputTarget.focus();
    this.toggleResetButton();
  }

  handleInput() {
    this.toggleResetButton();
  }

  toggleResetButton() {
    if (this.searchInputTarget.value.trim() === "") {
      this.resetButtonTarget.classList.add("hidden");
    } else {
      this.resetButtonTarget.classList.remove("hidden");
    }
  }

  populateFormsTable(event) {
    event.preventDefault();

    // Load projects from event target data-projects attribute
    const projects = JSON.parse(event.target.dataset.projects);
    const {field} = event.target.dataset;
    const table = document.getElementById("all-forms-table");

    // Set the Panel title
    document.querySelector("#view-forms-panel .fluid-modal-title").textContent = field;

    // Clear any existing table rows
    table.innerHTML = "";

    // Populate the table with the projects
    projects.forEach((project) => {
      const row = document.createElement("tr");
      row.innerHTML = `
        <td><a href='${project.url}' target='_blank'>${project.name}</a></td>
        <td>${project.state}</td>
      `;
      table.appendChild(row);
    });
  }
}
